<template>
  <!-- 记录 -->
  <van-popup v-model="isShow" :style="{ width: '80%' }" overlay-class="overlay">
    <div class="box_popup borders t-white fs-md">
      <div class="mx-15 my-20">
        <h3 class="fs-xxl t-center">{{ $t("blindBox.exrecord") }}</h3>
        <van-pull-refresh
          v-model="refreshing"
          @refresh="onRefresh"
          :pulling-text="$t('public.pulling')"
          :loosing-text="$t('public.loosing')"
          :loading-text="$t('public.loading')"
          class="pullrefresh my-10"
        >
          <van-list
            v-model="loading"
            :finished="finished"
            :loading-text="$t('public.loading')"
            :finished-text="$t('public.nomores')"
            :offset="150"
            @load="onLoad"
          >
            <div
              class="d-flex ai-center jc-between m-t-20 fs-lg border_b py-10"
            >
              <p>{{ $t("blindBox.time") }}</p>
              <p>{{ $t("blindBox.status") }}</p>
            </div>
            <div
              class="d-flex ai-center jc-between fs-sm border_b py-10"
              v-for="(item, index) in cardRecordList"
              :key="index"
            >
              <p class="t-color2">{{ item.created_at }}</p>
              <!-- 0-确认中 1-成功 3-失败 -->
              <p class="t-color5" v-if="item.status == 0">
                {{ $t("blindBox.confirming") }}
              </p>
              <p class="t-color8" v-else-if="item.status == 1">
                {{ $t("blindBox.extracted") }}
              </p>
              <p class="t-color9" v-else>{{ $t("blindBox.faild") }}</p>
            </div>
          </van-list>
        </van-pull-refresh>
        <div class="d-flex ai-center jc-center refresh fs-md" v-if="cardRecord">
          <p class="m-r-5" v-if="lang == 'zh'">共 {{ cardRecord.total }} 条</p>
          <p class="m-r-5" v-else>{{ cardRecord.total }} in total</p>
          <van-icon name="replay" color="#00A8FF" @click="onRefresh" />
        </div>
      </div>
    </div>
    <div class="d-flex ai-center jc-center m-t-20">
      <van-image
        width="21px"
        height="21px"
        :src="require('@/assets/cancel@2x.png')"
        @click="closeRecord"
      />
    </div>
  </van-popup>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    isShow: { type: Boolean, default: false },
  },
  data() {
    return {
      loading: false,
      finished: false,
      refreshing: false,
      totalPage: 1,
      pageNumber: 1,

      cardRecordList: [],
      cardRecord: {},
    };
  },
  computed: {
    ...mapState({
      walletAddress: (state) => state.walletAddress,
      lang: (state) => state.lang,
    }),
  },
  mounted() {
    this.init();
  },
  methods: {
    //页面初始化之后会触发一次，在页面往下加载的过程中会多次调用【上拉加载】
    onLoad() {
      this.loading = false;
      setTimeout(() => {
        if (this.pageNumber >= this.totalPage) {
          this.finished = true;
          this.loading = false;
        } else {
          this.pageNumber++;
          this.getcardrecord();
        }
      }, 500);
    },
    init() {
      this.totalPage = 1;
      this.pageNumber = 1;
      this.getcardrecord();
    },
    onRefresh() {
      setTimeout(() => {
        this.totalPage = 1;
        this.pageNumber = 1;
        this.getcardrecord();
      }, 1000);
    },
    //盲盒记录列表
    getcardrecord() {
      let that = this;
      this.loading = true;
      this.$axios
        .get(this.$api.cardrecord, { page: this.pageNumber, page_size: 8 })
        .then((res) => {
          // console.log(res.data);
          if (res.code == 200) {
            this.loading = false;
            this.finished = false;
            this.refreshing = false;
            this.cardRecord = res.data;
            let arr = res.data.list;
            if (this.pageNumber == 1) {
              this.cardRecordList = [];
              this.cardRecordList = arr;
              this.totalPage = res.data.totalPage;
            } else {
              this.cardRecordList.push(...arr);
            }
          } else {
            this.loading = false;
            that.$toast(res.msg);
          }
        });
    },
    closeRecord() {
      this.$emit("closeRecord");
    },
  },
};
</script>

<style lang="scss" scoped>
.van-popup {
  background: none;
}
.border_b {
  border-bottom: 1px solid rgba($color: #9998c2, $alpha: 0.3);
}
.box_popup {
  .pullrefresh {
    height: 360px;
    overflow: scroll;
  }
}
</style>