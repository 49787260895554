<template>
  <div class="containers">
    <navbar></navbar>
    <div class="contents px-40 py-15">
      <p class="bold fs-xxl t-center">UANG NFT</p>
      <p class="t-color2 fs-md t-center m-t-5">{{ $t("blindBox.tips1") }}</p>
      <div class="bg-color6 main d-flex flex-column ai-center jc-center m-t-10">
        <van-image
          width="218px"
          height="252px"
          :src="require('@/assets/manghe.png')"
        />
        <div class="d-flex ai-center jc-between m-b-20 w-100">
          <p class="t-color10">{{ $t("blindBox.shopType") }}</p>
          <p class="bold">{{ $t("blindBox.blindoxes") }}</p>
        </div>
        <div class="d-flex ai-center jc-between m-b-20 w-100">
          <p class="t-color10">{{ $t("blindBox.shopName") }}</p>
          <p class="bold">UANG NFT</p>
        </div>
        <div class="d-flex ai-center jc-between m-b-20 w-100">
          <p class="t-color10">{{ $t("blindBox.shopPrice") }}</p>
          <p class="bold">
            <span class="t-color5">{{ priceBox | format }}</span> {{ cionName }}
          </p>
        </div>
        <div class="fs-sm m-t-40 d-flex ai-center">
          {{ $t("store.banlece") }}:
          <van-skeleton :row="1" row-width="50px" :loading="isblance">
            {{ balances | format }} {{ cionName }}
          </van-skeleton>
        </div>
        <!-- 授权 -->
        <van-button
          class="bold borders btn approve_btn t-white"
          v-if="showApprove"
          :loading="isApprove"
          @click="onApprove"
          ><span class="fs-lg">{{ $t("blindBox.approve") }}</span></van-button
        >
        <!-- 购买 -->
        <van-button
          color="#074CE7"
          class="bold btn"
          v-if="showBuy"
          @click="onBuy"
          :loading="isBuy"
          ><span class="fs-lg">{{ $t("store.buynow") }}</span></van-button
        >
        <!-- <van-button
          color="#FF7E00"
          class="bold btn"
          @click="onDraw"
          v-if="showDraw"
          ><span class="fs-lg">{{ $t("blindBox.drawnow") }}</span></van-button
        > -->
        <p class="fs-md my-30">
          <span v-if="cardRecord.confirm > 0"
            >{{ cardRecord.confirm }} {{ $t("blindBox.tips2") }}</span
          >
          <span class="t-color4" @click="viewMore"
            >【{{ $t("blindBox.lookecord") }}】</span
          >
        </p>
      </div>
    </div>
    <!-- 盲盒购买确认中 -->
    <Confirming
      ref="confirming"
      :isShow="isMangShow"
      :isMangClick="true"
      @extract="onextract"
      @close="onCClose1"
    ></Confirming>
    <Confirming :isShow="isShow" :isMang="true" @close="onCClose2"></Confirming>
    <!-- 获得卡牌 -->
    <GetSuc
      :isShow="isGet"
      :isCard="false"
      :level="drawlevel"
      :name="drawName"
      :cardId="draw_cardId"
      :imgUrl="drawimgUrl"
      @close="onClose"
    ></GetSuc>
    <!-- 记录 -->
    <ViewMore :isShow="isRecord" @closeRecord="closeRecord"></ViewMore>
    <audio :src="require('@/assets/audio/zha.mp3')"></audio>
  </div>
</template>

<script>
import BigNumber from "bignumber.js";
import Confirming from "@/components/common/confirming";
import GetSuc from "@/components/common/getSuc";
import ViewMore from "@/components/viewMore";
import mixin from "@/common/web3";
import abi_U from "@/common/abi/abi_U.json";
import abi_G from "@/common/abi/abi_G.json";
import { mapState } from "vuex";
export default {
  components: { Confirming, GetSuc, ViewMore },
  mixins: [mixin],
  data() {
    return {
      isShow: false,
      isMangShow: false,
      isGet: false,
      isRecord: false,

      showApprove: true, //授权按钮
      isApprove: false,
      showBuy: false, //立即购买按钮
      isBuy: false,
      // showDraw: false, //立即抽取按钮
      priceBox: "--", //盲盒的价格
      receive_address: "", //盲盒收币地址
      contract_address: "", //盲盒支付币种合约/代币UANG合约
      contract_address2: "", //游戏道具合约
      txHash: "", //交易哈希
      cionName: "UANG", //代币名称
      appoveBlance: null, //授权额度
      decimals: 9, //精度
      balance: null, //余额
      draw_id: null, //抽取到的盲盒id
      drawlevel: 1, //抽取到的盲盒等级
      draw_cardId: null, //抽取到的盲盒卡片id
      drawimgUrl: "", //抽取到的盲盒卡片图片
      drawName: "", //抽取到的盲盒名称
      cardRecord: {},
      timer: null,
      isblance: false,
    };
  },
  computed: {
    ...mapState({
      setting: (state) => state.setting,
      walletAddress: (state) => state.walletAddress,
    }),
    balances() {
      if (this.decimals) {
        let balance = BigNumber(this.balance)
          .div(Math.pow(10, this.decimals))
          .toFixed();
        return balance;
        // if (balance.indexOf("e") != -1) {
        //   return
        // }
      }
    },
  },
  created() {
    this.$store.dispatch("getSetting"); //网站配置
  },
  mounted() {
    // var audio = document.querySelector("audio");
    //   audio.play();
    //   audio.pause();
    this.getSet();
    this.getcardrecord();
    this.getCoinName();
    this.getBlance();
    this.getdecimals();
    this.getAppoveBlance();
  },
  destroyed() {
    clearInterval(this.timer);
  },
  methods: {
    onClose() {
      clearInterval(this.timer);
      this.isGet = false;
      this.getBlance();
    },
    onCClose1() {
      clearInterval(this.timer);
      this.isMangShow = false;
    },
    onCClose2() {
      clearInterval(this.timer);
      this.isShow = false;
      setTimeout(() => {
        this.getBlance();
        this.getcardrecord();
      }, 1000);
    },
    closeRecord() {
      this.isRecord = false;
    },
    viewMore() {
      this.isRecord = !this.isRecord;
    },
    //获取余额 UANG
    async getBlance() {
      this.isblance = true;
      let contract = new web3.eth.Contract(abi_U, this.contract_address, {
        from: this.walletAddress,
      });
      contract.methods
        .balanceOf(this.walletAddress)
        .call({ from: this.walletAddress })
        .then((res) => {
          // 107,000,000,000,000
          this.balance = res;
          this.isblance = false;
        });
    },
    //获取币种名称
    getCoinName() {
      let contract = new web3.eth.Contract(abi_U, this.contract_address);
      contract.methods
        .symbol()
        .call({ from: this.walletAddress }, (err, res) => {
          this.cionName = res;
        });
    },
    //获取网站配置
    getSet() {
      if (this.setting) {
        let set = JSON.parse(this.setting);
        set.forEach((element) => {
          if (element.id == 40) {
            //抽一卡片盲盒费用
            this.priceBox = element.value;
          } else if (element.id == 3) {
            //盲盒抽卡收币地址
            this.receive_address = element.value;
          } else if (element.id == 5) {
            //抽盲盒用UANG币合约
            this.contract_address = element.value;
          } else if (element.id == 55) {
            //游戏道具合约
            this.contract_address2 = element.value;
          }
        });
      }
    },
    getcardrecord() {
      this.$axios
        .get(this.$api.cardrecord, { page: 1, page_size: 8 })
        .then((res) => {
          this.cardRecord = res.data;
        });
    },
    // 1.查询授权额度 够不够盲盒的价格 不够需要重新授权(授权方法是approve(address,amount)   address-授权给哪个地址 amount-授权多少额度)
    // 2.授权后可以购买 调用游戏道具合约的buyCard(amount)  amount-盲盒价格
    // 3.跟合约交互完 拿到哈希后 回调给API

    //查询精度
    getdecimals() {
      let contract = new web3.eth.Contract(abi_U, this.contract_address);
      contract.methods
        .decimals()
        .call({ from: this.walletAddress }, (err, res) => {
          this.decimals = res;
          this.$forceUpdate();
        });
    },
    //查询授权额度
    getAppoveBlance() {
      let contract = new web3.eth.Contract(abi_U, this.contract_address);
      contract.methods
        .allowance(this.walletAddress, this.contract_address2)
        .call({}, (err, res) => {
          // this.lines = res;
          // this.newlines;
          let line = BigNumber(res).div(Math.pow(10, this.decimals)).toFixed();
          // let i = Number(line).toLocaleString().replace(/,/g, "");
          let value = BigNumber(line).comparedTo(this.priceBox).toString(); //1为大于，-1为小于，0为等于
          if (value == -1) {
            this.showApprove = true;
            this.showBuy = false;
          } else {
            this.showApprove = false;
            this.showBuy = true;
          }
        });
    },
    //授权
    async onApprove() {
      if (Number(this.balance) < this.priceBox) {
        this.$toast(this.$t("public.noblance"));
      } else {
        this.isApprove = true;
        let amount = BigNumber(this.priceBox)
          .times(10)
          .times(Math.pow(10, this.decimals))
          .toFixed();
        // let amount =
        //   "0x" +
        //   BigNumber(v)
        //     .toString(16)
        //     .replace(/\.\w+$/, "");
        let contract = new web3.eth.Contract(abi_U, this.contract_address);
        await contract.methods
          .approve(this.contract_address2, amount)
          .send({ from: this.walletAddress }, (err, res) => {
            if (res) {
            } else {
              //授权失败
              this.isApprove = false;
              this.$toast(this.$t("public.approveFail"));
            }
          })
          .on("transactionHash", (hash) => {
            // console.log(hash);
          })
          .on("receipt", (receipt) => {
            this.isApprove = false;
            this.showApprove = false;
            this.showBuy = true;
            // this.onBuy();
          })
          .on("confirmation", (confirmation) => {
            // this.$toast(this.$t("public.approveFail"));
          });
        this.onBuy();
      }
    },
    //立即购买
    onBuy() {
      // var audio = document.querySelector("audio");
      // audio.play();
      // audio.pause();
      // return;
      if (Number(this.balance) < this.priceBox) {
        this.$toast(this.$t("public.noblance"));
      } else {
        this.isBuy = true;
        let contract = new web3.eth.Contract(abi_G, this.contract_address2);
        let amount = BigNumber(this.priceBox)
          .times(Math.pow(10, this.decimals))
          .toFixed();
        // let amount =
        //   "0x" +
        //   (this.priceBox * Math.pow(10, this.decimals))
        //     .toString(16)
        //     .replace(/\.\w+$/, "");
        contract.methods
          .buyCard(amount)
          .send(
            {from: this.walletAddress},
            (err, res) => {
              // console.log(err);
              if (res) {
                this.isBuy = false;
                this.tranfer(res, 1);
              }
              if (err) {
                return (this.isBuy = false);
              }
            }
          )
          .on("receipt", (receipt) => {
            this.isBuy = false;
            this.getAppoveBlance();
          })
          .on("error", (err) => {
            console.log(err);
            this.$toast("failure");
            this.isBuy = false;
            this.isMangShow = false;
            this.isShow = false;
            clearInterval(this.timer);
          });
      }
    },
    //抽盲盒回调
    tranfer(hash, status) {
      this.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: "loading...",
      });
      this.$axios
        .post(this.$api.drawbox, {
          txhash: hash,
          status: status,
        })
        .then((res) => {
          this.$toast.clear();
          this.getBlance();
          this.draw_id = res.data.draw_id;
          this.isMangShow = true;
        });
    },
    // 轮询盲盒  当user_card_id 不为null 时 显示卡片图片和等级
    getDrawInfo() {
      this.$axios
        .post(this.$api.checkDraw, {
          draw_id: this.draw_id,
        })
        .then((res) => {
          //  status =0 等待 =1成功 =3 交易失败
          if (res.data.status == 3) {
            this.$toast("failure");
            this.isBuy = false;
            this.isMangShow = false;
            this.isShow = false;
            clearInterval(this.timer);
          } else {
            if (res.data.user_card_id != null) {
              clearInterval(this.timer);
              this.drawlevel = res.data.level;
              this.drawName =
                this.lang == "zh" ? res.data.name : res.data.en_name;
              this.drawimgUrl = res.data.img;
              this.draw_cardId = res.data.user_card_id;
              setTimeout(() => {
                this.isShow = false;
                this.isMangShow = false;
                this.isGet = true;
              }, 200);
            }
          }
        });
    },
    //点击盲盒抽取卡片
    onextract() {
      let that = this;
      setTimeout(() => {
        this.isMangShow = false;
        this.isShow = true;
        this.timer = setInterval(() => {
          that.getDrawInfo();
        }, 2000);
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  border-radius: 10px;
  padding: 18px 16px;
}
.btn {
  width: 250px;
  border-radius: 10px;
  height: 30px;
  margin-top: 10px;
}
.approve_btn {
  padding: 0;
  border-image: linear-gradient(139deg, #15a3ee, #391aeb);
}
</style>